@import '../../../styles/customMediaQueries.css';

.root {
  display: flex;
  flex-direction: column;
  /* background-color: var(--colorGrey50); */
  position: relative;
}

.filters {
  & > *:not(:last-child) {
    margin-right: 8px;
    /* margin-bottom: 8px; */
    height: auto;
  }
}
